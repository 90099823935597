








import { Component, Vue } from 'vue-property-decorator';

@Component({
  props: {
    width: { type: String, default: '50px' },
    height: { type: String, default: '50px' },
  },
})
export default class Loading extends Vue {}
