































































import { Component, Vue } from 'vue-property-decorator';
import BaseIcon, { iconProps } from './BaseIcon.vue';

@Component({
  components: { BaseIcon },
  props: {
    ...iconProps,
    abilityIndex: { type: Number },
    className: { type: String },
  },
})
export default class AbilityLogo extends Vue {}
