









import { Component, Vue } from 'vue-property-decorator';
import BaseIcon, { iconProps } from './BaseIcon.vue';

@Component({
  components: { BaseIcon },
  props: { ...iconProps },
})
export default class Icon extends Vue {}
