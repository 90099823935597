

























import { Component, Vue } from 'vue-property-decorator';

import QuickLinks from './QuickLinks.vue';

@Component({
  components: {
    QuickLinks,
  },
  props: {
    showNav: {
      type: Boolean,
      default: true,
    },
    backRoute: {
      type: String,
      default: '/mode',
    },
    title: {
      type: String,
      default: '',
    },
  },
})
export default class RootNav extends Vue {}
