








import { Component, Vue } from 'vue-property-decorator';

import { iconProps } from './BaseIcon.vue';
import SorcererIcon from './sorcerer.vue';
import UnknownIcon from './unknown.vue';
import WarriorIcon from './warrior.vue';

@Component({
  components: {
    SorcererIcon,
    UnknownIcon,
    WarriorIcon,
  },
  props: {
    className: { type: String },
    ...iconProps,
  },
})
export default class RootNav extends Vue {}
