







































































































import { Component, Vue } from 'vue-property-decorator';
import { ref } from '@vue/composition-api';
import { classes } from '@battletris/shared/functions/classes';
import ViewWrapper from './ViewWrapper.vue';

import AbilityLogo from '../icons/AbilityLogo.vue';
import Loading from './Loading.vue';
import Tooltip from './Tooltip.vue';
import AbilityTooltip from './AbilityTooltip.vue';
import ClassLogo from '../icons/ClassLogo.vue';
import currUser from '../lib/User';
import KeyMapSelect from './KeyMapSelect.vue';

@Component({
  components: {
    AbilityLogo,
    AbilityTooltip,
    ClassLogo,
    KeyMapSelect,
    Loading,
    Tooltip,
    ViewWrapper,
  },
  props: {
    minimal: {
      type: Boolean,
      default: false,
    },
    user: {},
  },
  setup(props) {
    const user: any = props.user;
    const name = ref<string>(user.name);
    const className = ref(user.className);
    const userId = ref(user.id);
    const disabled = ref(user.id !== currUser.id);
    const activeKeyMapId = ref(user.activeKeyMap);

    const getDisplayClass = (getForClass: string) => ({
      name: getForClass,
      baseArmor: classes[getForClass].baseArmor,
      baseMana: classes[getForClass].baseMana,
      maxArmor: classes[getForClass].getArmorForLevel(15),
      maxMana: classes[getForClass].getManaForLevel(15),
      abilities: classes[getForClass].abilities.map((ability) => {
        const ticks = ability?.ticks || 0;
        const tickTimeout = ability?.tickTimeout || 0;
        const msDuration = ticks > 0 ? (ticks - 1) * tickTimeout : 0;

        return {
          duration: Math.round((msDuration / 1000) * 10) / 10,
          mana: ability.mana,
        };
      }),
    });
    const classIterator = Object.keys(classes)
      .filter((key) => key !== 'battletris')
      .map((key) => getDisplayClass(key));
    const abilityIterator = ref([1, 2, 3, 4]);
    const activeClassIndex = ref(
      classIterator.findIndex(({ name: n }) => className.value === n),
    );
    const loading = ref(true);
    // class was deleted?
    if (activeClassIndex.value === -1) {
      activeClassIndex.value = 0;
      className.value = classIterator[0].name;
    }

    let debounce: ReturnType<typeof setTimeout>;
    const updateUser = () => {
      if (debounce) {
        window.clearTimeout(debounce);
      }

      if (name.value.length > 50) {
        name.value = name.value.slice(0, 50);
      }

      debounce = setTimeout(
        () => currUser.update(name.value, className.value, activeKeyMapId.value),
        500,
      );
    };

    const selectClass = (increase: number) => {
      activeClassIndex.value += increase;

      if (activeClassIndex.value < 0) {
        activeClassIndex.value = classIterator.length - 1;
      } else if (activeClassIndex.value >= classIterator.length) {
        activeClassIndex.value = 0;
      }

      className.value = classIterator[activeClassIndex.value].name;
      updateUser();
    };

    return {
      abilityIterator,
      activeClassIndex,
      activeKeyMapId,
      classIterator,
      className,
      disabled,
      loading,
      name,
      selectClass,
      updateUser,
      userId,
    };
  },
})
export default class Settings extends Vue {}
