

























































































































































































import { onUnmounted, onMounted, ref } from '@vue/composition-api';
import { Component, Vue } from 'vue-property-decorator';

import { GameUser, Blocks } from '@battletris/shared';
import AbilityLogo from '../icons/AbilityLogo.vue';
import ClassLogo from '../icons/ClassLogo.vue';
import Controls from '../components/Controls.vue';
import KeyHandler from './KeyHandler';
import currUser from '../lib/User';
import Effect from '../components/Effect.vue';
import FrontendGameUser from './GameUser';
import GameRenderer, { colorMap } from './GameRenderer';
import Tooltip from '../components/Tooltip.vue';
import GameRegistry from './GameRegistry';

interface GameFieldProps {
  userData: GameUser;
  userIndex: number;
}

@Component({
  components: {
    AbilityLogo,
    ClassLogo,
    Controls,
    Effect,
    Tooltip,
  },
  props: {
    // total amount of playing users
    gameUserCount: { type: Number },
    // initial user data
    userData: {},
    // displayed users game index
    userIndex: { type: Number },
    // index of the currUser in the game
    activeUserIndex: { type: Number },
    // are we playing alone? (disable abilities and stuff)
    offline: {
      type: Boolean,
      default: false,
    },
    // list of lost users
    lostUsers: { type: [Number] },
  },
  setup(props) {
    const { userData, userIndex } = (props as unknown) as GameFieldProps;

    // vue param setup
    const isCurrUser = ref<boolean>(currUser.id === userData.id);
    const container = ref();
    const userId = ref(userData.id);
    const userName = ref(userData.name);
    const className = ref(userData.className);
    const maxArmor = ref(userData.maxArmor);
    const maxMana = ref(userData.maxMana);
    const maxExp = ref(userData.maxExp);
    const blockColors = ref(colorMap.STONES);
    // stat values
    const armor = ref<number>();
    const exp = ref<number>();
    const level = ref<number>();
    const blockCount = ref<number>();
    const cooldowns = ref<number[]>([]);
    const effects = ref<number[][]>([]);
    const hasLost = ref<boolean>();
    const mana = ref<number>();
    const nextBlocks = ref<number[][][]>();
    const nextBlocksToRender = 3;
    const rowCount = ref<number>();
    const speed = ref<number>();
    const hold = ref<number>();
    const holdLock = ref<boolean>(false);

    // non vue values
    let target = -1;

    /**
     * if this is the handler of the activly playing user, we can handle the active target focus
     * quite hacky to do it here, but all other ways are strange
     *   - in Game.vue we need to handle all users and format them again
     *   - event handlers are complexer than this
     */
    const updateTargetRendering = (newTargetIndex: number) => {
      target = newTargetIndex;

      // use direct css class accessor, vue will renrender the field and will cause flickering
      const gameField = document.getElementById(`game-field-${userIndex}`);
      gameField?.classList.remove('is-targeting');
      gameField?.classList.remove('is-self-targeting');
      if (target === props.activeUserIndex) {
        gameField?.classList.add(
          !isCurrUser.value ? 'is-targeting' : 'is-self-targeting'
        );
      }

      if (isCurrUser.value) {
        // remove targeted active game fields
        const previousTargeted = Array.from(
          document.getElementsByClassName('targeted-game-field'),
        );
        previousTargeted.forEach((el) => el.classList.remove('targeted-game-field'));
        // select the new target and add the targeted game field
        const newTarget = document.getElementById(`game-field-${target}`);
        newTarget?.classList.add('targeted-game-field');
      }
    };

    const updateNextBlocks = (user: FrontendGameUser) => {
      if (!user?.nextBlocks) {
        return;
      }

      nextBlocks.value = user.nextBlocks
        .slice(0, nextBlocksToRender)
        .map((blockId: number) => {
          // reduce empty block rows
          const block = [...Blocks[blockId][0]];
          return block.filter((y: number[]) => y.find((x: number) => !!x));
        });
    };

    const paramPropMapping = {
      armor,
      blockCount,
      effects,
      mana,
      rowCount,
      speed,
      cooldowns,
      maxArmor,
      maxMana,
      maxExp,
      exp,
      level,
      hold,
      holdLock,
      lost: hasLost,
    };

    // will be initialized after on mounted
    let gameRenderer: GameRenderer;
    let keyHandler: KeyHandler;
    const UserClass = FrontendGameUser;
    const gameUser = new UserClass(
      userData,
      userIndex,
      props.gameUserCount as number,
      (user) => {
        // only update when needed to reduce "flickering"
        Object.keys(paramPropMapping).forEach((prop) => {
          if (typeof user[prop] !== 'undefined') {
            paramPropMapping[prop].value = user[prop];
          }
        });

        // update target hints
        if (user.target !== target) {
          updateTargetRendering(user.target);
        }
        // rerender next blocks
        if (user.nextBlocks) {
          updateNextBlocks(user);
        }
      },
    );

    // register the game user in the game registry, so every game user class in the same room can
    // access this one
    GameRegistry[userIndex] = gameUser;

    // watch for user input, if the current user is participating the match
    if (isCurrUser.value) {
      keyHandler = new KeyHandler(currUser, gameUser);
      keyHandler.listen();
    }

    onMounted(() => {
      gameRenderer = new GameRenderer(container.value, gameUser, {
        animation: true,
        preview: true,
      });
      // ensure initial rendered target
      updateTargetRendering(target as number);
      updateNextBlocks(gameUser);
    });

    // be sure to stop watching, when game was left
    onUnmounted(() => {
      if (keyHandler) {
        keyHandler.stop();
      }
      gameRenderer.destroy();
      gameUser.stop();
    });

    return {
      armor,
      blockColors,
      blockCount,
      Blocks,
      className,
      container,
      cooldowns,
      effects,
      exp,
      hasLost,
      hold,
      holdLock,
      isCurrUser,
      level,
      mana,
      maxArmor,
      maxExp,
      maxMana,
      nextBlocks,
      rowCount,
      speed,
      target,
      userId,
      userName,
    };
  },
})
export default class Game extends Vue { }
