






























import { Component, Vue } from 'vue-property-decorator';
import { classes } from '@battletris/shared/functions/classes';
import { ref } from '@vue/composition-api';
import Tooltip from './Tooltip.vue';
import AbilityLogo from '../icons/AbilityLogo.vue';

export const iconProps = {
  color: { type: String, default: '#e2e8f0' },
  width: { type: String, default: '100%' },
  height: { type: String, default: 'auto' },
};

@Component({
  components: {
    AbilityLogo,
    Tooltip,
  },
  props: {
    className: { type: String },
    abilityIndex: { type: Number },
  },
  setup(props) {
    const ability = classes[props.className as string].abilities[props.abilityIndex as number];
    const mana = ref(ability.mana);
    const ticks = ability?.ticks || 0;
    const tickTimeout = ability?.tickTimeout || 0;
    const msDuration = ticks > 0 ? (ticks - 1) * tickTimeout : 0;
    const duration = ref(Math.round((msDuration / 1000) * 10) / 10);

    return {
      duration,
      mana,
    };
  },
})
export default class AbilityControl extends Vue {}
