









































import { Component, Vue } from 'vue-property-decorator';
import { ref } from '@vue/composition-api';
import { classes } from '@battletris/shared/functions/classes';
import AbilityTooltip from './AbilityTooltip.vue';
import AbilityLogo from '../icons/AbilityLogo.vue';
import Control from './Control.vue';

@Component({
  components: {
    AbilityLogo,
    AbilityTooltip,
    Control,
  },
  props: {
    abilityIndex: { type: Number },
    className: { type: String },
    keyText: { type: String },
    keyValue: { type: Number },
    userMana: { type: Number },
    cooldown: { type: Number },
  },
  setup(props) {
    const control = ref();
    const mana = ref(
      classes[props.className as string].abilities[props.abilityIndex as number]
        .mana,
    );

    // proxy control mouse down and up events
    const mouseDown = (touch: number) => control.value.mouseDown(touch);
    const mouseUp = (touch: number) => control.value.mouseUp(touch);

    // get current date time
    const dateNow = () => Date.now();

    return {
      control,
      dateNow,
      mana,
      mouseDown,
      mouseUp,
    };
  },
})
export default class AbilityControl extends Vue {}
